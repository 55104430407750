<template>
	<edit-template class="form" @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(res=>$router.back()).catch(()=>{});" :cancelRemind="false" confirmText="保存" cancelText="取消">

    <el-form :model="form" :rules="rules" ref="form" label-width="150rem" style="width: calc(100% - 300rem);margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
      <el-form-item label="考试类型名称" prop="name">
        <el-input placeholder="请输入" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="考试范围" prop="scope">
        <el-select :popper-append-to-body="false" v-model="form.scope">
          <el-option v-for="(item ,index) in scopeList" :label="item" :value="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="适用校区" prop="school_ids">
        <div v-if="schoolList.length>1">
          <el-checkbox v-model="allSchool" @change="changeAll">
            全部校区
          </el-checkbox>
          <el-checkbox-group v-model="form.school_ids" @change="changeCheckbox">
            <el-checkbox v-for="(item,index) in schoolList" :label="item.key" :key="item.key+index" style="display: block;">{{item.val}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div v-else-if="schoolList.length === 1">
          {{ schoolList[0].val }}
        </div>
      </el-form-item>
      <el-form-item label="备注" prop="note">
<!--        <el-input placeholder="请输入" v-model="form.note" show-word-limit maxlength="300" rows="8" type="textarea" resize="none" style="width: 300rem;"></el-input>-->
        <t-textarea v-model="form.note" show-word-limit="" overflow="" maxlength="300" style="width: 300rem;" rows="8"></t-textarea>
      </el-form-item>
    </el-form>


</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data(){
    return{
      form:{
        name:null,
        scope:null,
        school_ids:[],
        note:null,
      },
      rules:{
        name:[{required:true,message:"请输入考试类型名称"},{pattern:/^[\u4e00-\u9fa5\w]{1,30}$/,message: "请输入30字以内的汉字，数字和字母"}],
        scope:[{required:true,message:"请输入考试类型名称"}],
        school_ids:[{required:true,message:"请选择适用校区"}],
        note:[{pattern:/^[\u4e00-\u9fa5\w\s,，.。!！;；\'\"‘“\[\]【】\(\)（）]{0,300}$/,message:"请输入300字以内的汉字，数字和字母"}]
      },
      schoolList:[],
      allSchool:false,
      scopeList:{}
    }
  },
  methods:{
    // 提交数据
    submit(){
      this.$refs.form.validate((res,e,p)=>{
        if (res){
          this.$_axios.post("/exam-type",this.form).then(res=>{
            this.$message.success(res.data.error.errorUserMsg);
            this.$store.commit("setPage",1);
            this.$router.back();
          })
        }else {
          /*let form = this.form;
          let empty = false;
          for (let k in e){
            let item = form[k];
            if (item==null || item === ""){
              empty = true;
              break;
            }
          }

          if (empty)
            this.$message.warning("请输入完整的考试信息!")
          else
            this.$message.warning("")*/
        }
      })
    },
    // 初始化
    init(){
      // 获取考试范围
      this.$_axios.get("/exam-type/scope").then(res=>{
        let {data} = res.data;
        this.scopeList = data;
      })
      // 获取校区列表
      this.$utils.commonApi.getSchoolList().then(res=>{
        let toArray = this.$tools.jsonToArray(res);
        let len = toArray.length;
        this.schoolList = toArray;
        if (len === 1)this.form.school_ids = [toArray[0].key]
      })
    },
    // 校区全选被更改
    changeAll(e){
      this.form.school_ids = e?this.schoolList.map(res=>res.key):[];
    },
    // 校区选择框被更改
    changeCheckbox(e){
      this.allSchool = e.length === this.schoolList.length
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped></style>
